@keyframes progress {
  0% {
    width: 0;
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.container {
  position: relative;
  width: 90%;
  height: 50px;
  margin: 10px auto;
  .progressbar {
    width: 100%;
    margin: 15px 0px;
    &__title {
      font-size: 15px;
      line-height: 20px;
      font-weight: bold;
      color: $white;
    }
    &__skill {
      height: 10px;
      width: 100%;
      border: solid 1px;
      border-color: rgba($color: $white, $alpha: 0.1);
      border-radius: 6px;
      margin-top: 10px;
      background: rgba($color: #000000, $alpha: 0.2);
    }
    &__skill-per {
      position: relative;
      display: block;
      height: 100%;
      border-radius: 6px;
      background: $colorGradient;
      animation: progress 0.4s ease-in-out forwards;
    }
    .tooltip {
      position: absolute;
      right: 0px;
      top: 0px;
      font-size: 12px;
      line-height: 15px;
      color: $white;
      padding: 2px 6px;
    }
    .HTML {
      width: 100%;
    }
    .CSS {
      width: 100%;
      animation-delay: 0.1s;
      animation-fill-mode: backwards;
    }
    .JavaScript {
      width: 90%;
      animation-delay: 0.2s;
      animation-fill-mode: backwards;
    }
    .ReactJS {
      width: 90%;
      animation-delay: 0.3s;
      animation-fill-mode: backwards;
    }
    .NodeJS {
      width: 60%;
    }
    .ExpressJS {
      width: 40%;
      animation-delay: 0.1s;
      animation-fill-mode: backwards;
    }
    .MongoDB {
      width: 90%;
      animation-delay: 0.2s;
      animation-fill-mode: backwards;
    }
  }
}
