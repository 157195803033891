.skills-page {
  margin: auto;
  .competences {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 50px;
  }
}

@media screen and (max-width: 720px) {
  .skills-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    .competences {
      flex-direction: column;
      width: 90%;
    }
  }
}
