.contact-page {
  margin: auto;
  .contact-back {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 60px auto;
  }
}

@media screen and (max-width: 720px) {
  .contact-page {
    .contact-back {
      flex-direction: column;
    }
  }
}
