@font-face {
  font-family: "Montserrat";
  src: url("../Assets/fonts/Montserrat-Regular.otf");
}

@font-face {
  font-family: "MontserratBold";
  src: url("../Assets/fonts/Montserrat-Bold.otf");
}

$fontRegular: "MontserratRegular", sans-serif;
$fontBold: "MontserratBold", sans-serif;

$colorBackground: #030712;
$colorBackgroundSecond: #1f2937;
$colorInput: #101827;
$colorGradient: linear-gradient(to right, #4efcbf, #49d5e5);
$colorRadient: radial-gradient(#4efcbf, #49d5e5);
$colorGreen: #4efcbf;
$colorBlue: #49d5e5;
$white: #ddd;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: $fontRegular;
  background-color: $colorBackground;
  color: $white;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}
