.accueil {
  display: flex;
  justify-content: space-around;
  margin: auto;
  width: 100%;
  height: 100vh;
  .accueil-presentation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    margin-left: 50px;
    .accueil-title {
      font-size: 28px;
      font-weight: lighter;
      line-height: 35px;
      color: rgb(165, 164, 164);
    }
    .accueil-description {
      font-size: 20px;
      line-height: 35px;
      margin: 40px 0px;
      padding-right: 20px;
    }
  }
  .accueil-pdf {
    display: flex;
    align-items: center;
    position: relative;
    .lien-pdf {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $colorBackground;
      background: $white;
      border-radius: 50%;
      width: 65px;
      transition: transform 0.1s ease-in-out;
      &:hover {
        background-color: rgba($white, $alpha: 1);
        transform: scale(1.1);
      }
      .download-icon {
        margin-top: 10px;
        height: 20px;
      }
      .title-pdf {
        font-size: 20px;
        line-height: 35px;
        font-weight: bold;
        margin: 0px 26px;
      }
    }
    .circle-one {
      position: relative;
      right: 65px;
      width: 65px;
      height: 65px;
      background: rgba($white, $alpha: 0.8);
      border-radius: 50%;
      z-index: -1;
      transition: transform 0.15s ease-in-out;
    }
    .hoveredOne {
      transform: scale(1.3);
    }
    .circle-two {
      position: relative;
      left: 65px;
      width: 65px;
      height: 65px;
      background: rgba($white, $alpha: 0.7);
      border-radius: 50%;
      z-index: -2;
      transition: transform 0.2s ease-in-out;
    }
    .hoveredTwo {
      transform: scale(1.5);
    }
  }
}

@media screen and (max-width: 900px) {
  .accueil {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .accueil-presentation {
      margin: 0;
      .accueil-title {
        font-size: 22px !important;
        font-weight: lighter;
        text-align: center;
      }
      .accueil-description {
        font-size: 16px !important;
        line-height: 35px;
        text-align: center;
        padding: 0 !important;
      }
    }
  }
}
