.lien-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 140px;
  height: 70px;
  background: $colorInput;
  border-radius: 15px;
  border: solid 1px $white;
  transition: transform 0.1s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  .title-contact {
    color: $white;
    font-size: 25px;
    line-height: 35px;
    margin: 5px 0px 0px 0px;
  }
  .icon-contact {
    color: $white;
    margin: 0px 0px 5px 0px;
    .svg-inline--fa {
      height: 20px;
    }
  }
}
