footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 15px;
  color: rgb(165, 164, 164);
  width: auto;
  text-align: center;
}
