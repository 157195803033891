.navbar {
  display: flex;
  position: relative;
  z-index: 10;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  min-height: 100px;
  padding: 1rem;
  background-color: $colorBackground;
  .navbar__links {
    display: flex;
    .navbar__link {
      font-size: 1.2rem;
      padding: 0 10px;
      color: $white;
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
      &:focus {
        opacity: 1;
      }
    }
    .button-theme {
      background-color: transparent;
      border: 0px;
      margin: 0px 20px 0px 20px;
      .fa-circle-half-stroke {
        color: $white;
      }
    }
  }

  .navbar__burger {
    background: transparent;
    border: none;
    color: $white;
    display: none;
    .svg-inline--fa {
      height: 30px;
    }
  }
}

.title {
  font-size: 28px;
  line-height: 35px;
  font-weight: lighter;
  margin: 0px 20px;
  &__name {
    font-family: $fontBold;
  }
}

@media screen and (max-width: 720px) {
  .navbar__links {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: -100vw;
    bottom: 0;
    width: 0;
    height: 100vh;
    padding: 2rem;
    background-color: $colorBackground;
    transition: all 0.8s ease-out;
  }
  .show-nav .navbar__links {
    right: 0;
    width: 100vw;
  }
  .navbar__item::after {
    content: "";
    display: block;
    margin: 10px auto;
    width: 8vw;
    height: 2px;
    background: $white;
    opacity: 0.6;
  }
  .navbar__item:last-child::after {
    display: none;
  }
  .navbar__link {
    display: block;
    margin: 20px auto;
    font-size: 5vw !important;
  }
  .navbar__burger {
    display: block !important;
    position: fixed;
    top: 2rem;
    right: 2rem;
    &:hover {
      cursor: pointer;
    }
  }
  .show-nav .svg-inline--fa {
    transform: rotate(90deg);
    transition: transform 0.2s ease-in-out;
  }
  .hide-nav .svg-inline--fa {
    transition: transform 0.2s ease-in-out;
  }
  .navbar__item {
    transform: translateY(100vh);
  }
  .show-nav .navbar__item {
    transform: translateY(0);
  }
  .show-nav .slide-1 {
    transition: all 1s ease-in-out;
  }
  .show-nav .slide-2 {
    transition: all 1.1s ease-in-out;
  }
  .show-nav .slide-3 {
    transition: all 1.2s ease-in-out;
  }
  .show-nav .slide-4 {
    transition: all 1.3s ease-in-out;
  }

  .title {
    font-size: 5vw;
  }
}
