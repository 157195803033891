.coord-info {
  display: flex;
  flex-direction: column;
  width: 45%;
  height: 400px;
  background-color: $colorBackgroundSecond;
  border-radius: 10px;
  margin: auto;
  &__title {
    text-align: center;
    font-size: 1.8rem;
    line-height: 90px;
    width: 90%;
    margin: 0px auto;
    border-bottom: solid 2px rgba($white, 0.8);
  }
  &__address {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 25px;
    margin: 30px 0px 10px 10%;
    .fa-location-crosshairs {
      width: 30px;
      height: 20px;
      margin: 0px 20px 0px 0px;
    }
  }
  &__phone {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 25px;
    margin: 10px 0px 10px 10%;
    .fa-mobile-screen-button {
      width: 30px;
      height: 20px;
      margin: 0px 20px 0px 0px;
    }
  }
  &__mail {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 25px;
    margin: 10px 0px 20px 10%;
    cursor: pointer;
    .fa-envelope {
      width: 30px;
      height: 20px;
      margin: 0px 20px 0px 0px;
    }
  }
  .button-lien {
    display: flex;
    justify-content: space-around;
    width: 80%;
    margin: auto;
  }
}

@media screen and (max-width: 720px) {
  .coord-info {
    width: 90%;
    margin-bottom: 15px;
    .button-lien {
      width: 90%;
    }
  }
}
