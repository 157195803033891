.contact-info {
  display: flex;
  flex-direction: column;
  width: 45%;
  height: 400px;
  background-color: $colorBackgroundSecond;
  border-radius: 10px;
  margin: auto;
  &__title {
    text-align: center;
    font-size: 1.8rem;
    line-height: 90px;
    width: 90%;
    margin: 0px auto;
    border-bottom: solid 2px rgba($white, 0.8);
  }
  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    height: 300px;
    margin: auto;
    .input-back {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      width: 100%;
      height: 32px;
      .input-name {
        width: calc(100% - 2px);
        height: 30px;
        border: 0px;
        border-radius: 10px;
        background-color: $colorInput;
        color: $white;
        font-size: 15px;
        font-family: $fontRegular;
        padding-left: 10px;
      }
      .input-email {
        width: calc(100% - 2px);
        height: 30px;
        border: 0px;
        border-radius: 10px;
        background-color: $colorInput;
        color: $white;
        font-size: 15px;
        font-family: $fontRegular;
        padding-left: 10px;
      }
    }
    .input-back-msg {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      width: 100%;
      height: 92px;
      .input-message {
        width: calc(100% - 2px);
        height: 90px;
        border: 0px;
        border-radius: 10px;
        background-color: $colorInput;
        color: $white;
        font-size: 15px;
        font-family: $fontRegular;
        padding-top: 5px;
        padding-left: 10px;
        resize: none;
      }
    }
    .button-submit {
      width: 140px;
      height: 35px;
      background: $colorInput;
      border-radius: 10px;
      border: solid 1px $white;
      color: $white;
      font-size: 20px;
      font-weight: bold;
      transition: transform 0.1s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .contact-info {
    width: 90%;
    margin-top: 15px;
  }
}
