@keyframes info {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#Myflix {
  .card {
    &__img {
      background-color: rgb(20, 20, 20);
    }
  }
}

.card {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
  width: 400px;
  height: 270px;
  margin: 30px;
  transition: transform 0.2s ease-in-out;
  &__img {
    object-fit: contain;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin: auto;
  }
  &__info {
    display: none;
    height: 270px;
    margin: auto;
    color: $white;
    .card__name {
      color: $white;
      font-size: 25px;
      font-weight: bold;
      line-height: 30px;
      width: 160px;
      text-align: center;
      margin: 20px 0px 0px 0px;
      cursor: pointer;
    }
    .card__description {
      font-size: 18px;
      line-height: 25px;
      font-weight: bold;
      text-align: center;
      width: 90%;
      cursor: pointer;
    }
    .svg-inline--fa {
      margin: 10px 10px;
      height: 30px;
    }
    .lien-button {
      color: $white;
    }
  }
  &:hover {
    transform: scale(1.1);
    .card__info {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background: rgba($color: $colorBackground, $alpha: 0.8);
      animation: info 0.5s ease-in-out forwards;
    }
  }
}

@media screen and (max-width: 720px) {
  .card {
    width: 80%;
    max-width: 400px;
    margin: 25px auto 25px auto;
    &__info {
      display: none;
      height: 100%;
      margin: 0px;
      .card__name {
        font-size: 20px;
        line-height: 25px;
        width: 160px;
        text-align: center;
        margin: 10px;
      }
      .card__description {
        font-size: 15px;
        line-height: 20px;
        font-weight: bold;
        text-align: center;
        width: 90%;
        cursor: default;
      }
      .svg-inline--fa {
        margin: 8px 10px;
        height: 30px;
      }
      .lien-button {
        color: $white;
      }
    }
    &:hover {
      transform: scale(1.1);
      transition: transform 0.2s ease-in-out;
      .card__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        background: rgba($color: $colorBackground, $alpha: 0.8);
        animation: info 0.5s ease-in-out forwards;
      }
    }
  }
}
